import React, { useState } from "react"
import "../assets/styles/index.scss"
import Head from "../components/global/head"
import ModalVideo from "react-modal-video"

import banner2x from "../assets/images/open-day/banner@2x.jpg"
import block1 from "../assets/images/open-day/block1.jpg"
import block2 from "../assets/images/open-day/block2.jpg"
import block3 from "../assets/images/open-day/block3.jpg"
import block4 from "../assets/images/open-day/block4.jpg"
import block5 from "../assets/images/open-day/block5.jpg"

import phone1 from "../assets/images/open-day/phone1.png"
import phone2 from "../assets/images/open-day/phone2.png"
import phone3 from "../assets/images/open-day/phone3.png"
import phone4 from "../assets/images/open-day/phone4.png"

import bigscreen1 from "../assets/images/open-day/bigscreen1.jpg"
import screen1 from "../assets/images/open-day/screen1.png"
import video1 from "../assets/images/open-day/video1.jpg"

const EightBar = () => {
  const [showFullStudy, setShowFullStudy] = useState(false)
  const [showVideo, setShowVideo] = useState(false)

  const handleOpenVideo = (e) => {
    e.preventDefault()
    setShowVideo(true)
  }
  const handleCloseVideo = () => {
    setShowVideo(false)
  }
  return (
    <>
      <Head
        description="Hardhat is an independent creative agency helping brands capitalise on the why, when, where, what and how of human behaviour."
        title="Case Study - Deakin Open Day"
        url="https://hardhat.com.au/open-day"
        image="https://hardhat.com.au/images/og.jpg"
        bodyClassName="old-case-studies"
      />
      <div>
        <div className="container case-study dark-header">
          <section className="hhd-head-banner js-banner-intro-animation">
            <h1>Deakin Open Day</h1>
            <div className="divider black center" />
          </section>
          <section className="hhd-head-banner no-padding js-banner-intro-animation">
            <div
              className="banner-image"
              style={{
                backgroundImage: `url(${banner2x})`,
              }}
            >
              Deakin Open Day - BREAK IT TO YOUR BARISTA
            </div>
          </section>
          <section className="hhd-case-intro js-fade-in-animation">
            <div className="hhd-case-intro-text">
              <p>
                We partnered with Deakin’s digital team to make the Uni’s 2014
                Open Day its best on record. In addition to an award winning
                site and companion app for the day, we took prospective student
                engagement to new heights with ‘Super Happy Prize Time’; a
                social activation that played out across a 15 screen mega wall,
                clocking tens of thousands of plays and an average engagement
                time of 42 minutes.
              </p>
              <hr />
            </div>
            <div className="hhd-case-awards">
              <div>
                MELBOURNE DESIGN AWARDS GOLD: MARKETING - BRANDED EXPERIENCE
              </div>
              <div>
                AMIA FINALIST - BEST DIGITAL ADVERTISING OR COMMUNICATION
              </div>
            </div>
            <div className="hhd-case-intro-more">
              <div
                className={`hhd-case-intro-more__full ${
                  showFullStudy ? "show" : "hide"
                }`}
              >
                <br />
                <br />
                <p>
                  A university’s Open Day is the biggest day of the year and its
                  best chance to attract new enrolments. Deakin knew that to
                  differentiate themselves, they needed to engage and appeal to
                  a tech-savvy audience.
                </p>
                <p>
                  We were engaged to make Deakin University’s Open Day the best
                  one on record. Our aim was to use digital to ensure that all
                  prospective students came away with Deakin as their first
                  preference.
                </p>
                <p>
                  In order to achieve this, we created a fully responsive,
                  beautifully designed website that had mobile users at its
                  core. The focus of the site was to arm prospective students
                  with everything they needed to know before heading to Deakin
                  Open Day. The site had information on each campus, every
                  session and plenty of content about what to expect, making it
                  a no brainer to attend.
                </p>
                <p>
                  With so much going on during Open Day and information needing
                  to be at people’s fingertips, we also produced an iOS and
                  Android app that allowed students to intuitively plan their
                  day, stay up to date with events and follow an interactive map
                  around campus.
                </p>
                <p>
                  To really set Deakin apart and build the type of buzz that
                  would get even the most savvy audience engaged, we created an
                  interactive, socially charged activation. As the name
                  suggests, Super Happy Prize Time was the ultimate game for
                  prospective students who used their phones to interact with a
                  15 screen megawall by logging in with Facebook and entering
                  coordinates to win a range of great prizes including Deakin
                  merchandise.
                </p>
                <p>
                  Deakin Open Day was the best on record, attracting over 15,000
                  prospective students and over 100,000 interactions across all
                  three digital platforms.
                </p>

                <p>
                  Engagement levels on the day were off the charts. The average
                  time spent playing Super Happy Prize Time was an incredible 42
                  minutes, resulting in over 33,000 guesses and a huge buzz
                  across Deakin’s social media properties on the day. We were
                  also able to demonstrate to prospective students, in no
                  uncertain terms, that Deakin is excited by and committed to
                  technology and innovation.
                </p>
                <p>
                  Since Open Day, Super Happy Prize Time has also picked up a
                  Gold Melbourne Design Award and been nominated as an AIMIA
                  finalist.
                </p>
              </div>
            </div>
            <a
              onClick={() => setShowFullStudy(!showFullStudy)}
              href="#"
              className="case-study-more button black button-partnership no-wrapper"
            >
              {!showFullStudy ? "READ FULL CASE STUDY" : "-"}
            </a>
          </section>
          <section className="hhd-case-image-block js-fade-in-animation">
            <div className="block-half">
              <img src={`${block5}`} width="100%" />
            </div>
            <div className="block-half">
              <div className="block-half">
                <img src={`${block1}`} width="100%" />
              </div>
              <div className="block-half">
                <img src={`${block2}`} width="100%" />
              </div>
              <div className="block-half">
                <img src={`${block3}`} width="100%" />
              </div>
              <div className="block-half">
                <img src={`${block4}`} width="100%" />
              </div>
            </div>
          </section>

          <section className="hhd-case-phone-breakout js-fade-in-animation bottom-padding">
            <img src={`${phone1}`} />
            <img src={`${phone2}`} />
          </section>

          <section className="hhd-case-bigscreen js-fade-in-animation">
            <img src={bigscreen1} width="100%" />
          </section>

          <section className="hhd-case-screen-breakout js-fade-in-animation">
            <img src={screen1} width="100%" />
          </section>

          <section className="hhd-case-phone-breakout js-fade-in-animation bottom-padding">
            <img src={`${phone3}`} />
            <img src={`${phone4}`} />
          </section>
          <section className="hhd-case-bigscreen-video js-hhd-video no-padding">
            <button className="play-vid" onClick={handleOpenVideo}>
              {" "}
              <div className="js-video-container latest-work-video-container">
                <img src={video1} width="100%" />
              </div>
              <span className="close-vid">
                <a href="#" className="close-vid-btn">
                  <i className="icon--stop" />
                </a>
              </span>
            </button>

            <ModalVideo
              channel="youtube"
              isOpen={showVideo}
              videoId="s-Q8QNz065s"
              onClose={handleCloseVideo}
            />
          </section>
        </div>
      </div>
    </>
  )
}
export default EightBar
